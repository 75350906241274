import {PriceModel} from './Price.model';
import {AggregatedTaxBreakdownFragment} from '../../gql/graphql';

export class AggregatedTaxBreakdownModel {
  public taxName?: string;
  public aggregatedTaxAmount: PriceModel;

  constructor(aggregatedTaxBreakdown?: AggregatedTaxBreakdownFragment | null) {
    this.taxName = aggregatedTaxBreakdown?.taxName ?? /* istanbul ignore next */ undefined;
    this.aggregatedTaxAmount = new PriceModel(aggregatedTaxBreakdown?.aggregatedTaxAmount);
  }
}
