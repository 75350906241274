import {FormOverride} from '@wix/form-viewer/dist/types/services/form-overrides';
import {CheckoutSettingsModel} from '../../../domain/models/checkoutSettings/CheckoutSettings.model';
import {ILocaleKeys} from '../../../locale-keys/LocaleKeys';
import {mergeDeep} from '../../../domain/utils/mergeDeep';
import {FormOverrides} from './../../../types/app.types';

export const mandatoryOverride: FormOverride = {
  hidden: false,
  required: true,
};

export function mergeOverrides(...overrides: FormOverrides[]): FormOverrides {
  return mergeDeep(...(overrides as {[key: string]: object}[]));
}

export function getSettingsOverrides({
  checkoutSettings,
}: {
  checkoutSettings: Pick<CheckoutSettingsModel, 'isLocalDeliveryByZipCodeEnabled'>;
}): FormOverrides {
  return {
    address: {
      postal_code: checkoutSettings.isLocalDeliveryByZipCodeEnabled ? mandatoryOverride : {},
    },
  };
}

export function getSettingsOverridesForCustomizableFields(
  checkoutSettings: Pick<CheckoutSettingsModel, 'addressLine2' | 'companyName' | 'phone' | 'customField'>
): FormOverrides {
  return {
    address: {
      address_line_2: {
        hidden: !checkoutSettings.addressLine2.show,
        required: checkoutSettings.addressLine2.mandatory,
      },
    },
    company_name: {
      hidden: !checkoutSettings.companyName.show,
      required: checkoutSettings.companyName.mandatory,
    },
    phone: {
      hidden: !checkoutSettings.phone.show,
      required: checkoutSettings.phone.mandatory,
    },
    additional_info_1: {
      hidden: !checkoutSettings.customField.show,
      required: checkoutSettings.customField.mandatory,
      label: checkoutSettings.customField.title,
    },
  };
}

export function getHideCountryOverride(): FormOverrides {
  return {address: {country: {hidden: true, required: false}}};
}

export function getDeliveryCountriesOverrides(
  enabledDeliveryCountries: CheckoutSettingsModel['enabledDeliveryCountries']
): FormOverrides {
  if (enabledDeliveryCountries.length) {
    return {address: {country: {countries: enabledDeliveryCountries}}};
  }
  return {};
}

export function getExcludeGoogleAutoCompleteOverrides({
  excludeGoogleAutoComplete,
}: {
  excludeGoogleAutoComplete?: boolean;
}): FormOverrides {
  return {
    address: {
      address_line: {
        autocomplete: !excludeGoogleAutoComplete,
      },
      street_name: {
        autocomplete: !excludeGoogleAutoComplete,
      },
    },
  };
}

export function getLabelsOverrides({localeKeys}: {localeKeys: ILocaleKeys}): FormOverrides {
  return {
    full_name: {
      first_name: {label: localeKeys.checkout.address_information.first_name_label()},
      last_name: {label: localeKeys.checkout.address_information.last_name_label()},
    },
    phone: {label: localeKeys.checkout.address_information.phone_number_label()},
    company_name: {label: localeKeys.checkout.address_information.company_label()},
  };
}
