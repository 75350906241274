import {PriceModel} from './Price.model';
import {ShippingOptionFragment} from '../../gql/graphql';
import {convertToDateTimeByTimezone} from '../utils/dateTime.utils';
import {AddressModel} from './Address.model';

export class SelectedShippingOptionModel {
  public code: string;
  public title: string;
  public deliveryTime: string;
  public instructions?: string;
  public pickupAddress?: AddressModel;
  public isPickup: boolean;
  public deliveryTimeSlot?: string | null;
  public hasTimeSlots?: boolean;
  public price: PriceModel;

  constructor(shippingOption: ShippingOptionFragment, options?: {timezone?: string}) {
    this.code = shippingOption.code!;
    this.title = shippingOption.title!;
    this.isPickup = !!shippingOption?.logistics?.pickupDetails;
    this.deliveryTime = shippingOption.logistics?.deliveryTime ?? '';
    this.instructions = shippingOption.logistics?.instructions ?? undefined;
    this.pickupAddress = shippingOption.logistics?.pickupDetails?.address
      ? new AddressModel(shippingOption.logistics.pickupDetails.address)
      : undefined;
    this.hasTimeSlots = Boolean(shippingOption?.logistics?.deliveryTimeSlot);
    if (this.hasTimeSlots) {
      const dateMillis = +shippingOption.logistics!.deliveryTimeSlot!.from!;
      const dateTime = convertToDateTimeByTimezone(dateMillis, options?.timezone);
      this.deliveryTimeSlot = dateTime.toLocaleString({
        weekday: 'short',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      });
    }

    this.price = new PriceModel(shippingOption.cost?.price);
  }
}
