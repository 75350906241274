import {DateTime} from 'luxon';

export const convertToDateTimeByTimezone = (millis: number, timezone?: string): DateTime =>
  DateTime.fromMillis(millis, {zone: 'UTC'}).setZone(timezone);

export const dateKeyFromDateTime = (dateTime: DateTime): string => `${dateTime.day}.${dateTime.month}.${dateTime.year}`;

export const dateKeyFromDate = (date: Date, shouldFixTimeslotsBug: boolean): string => {
  if (shouldFixTimeslotsBug) {
    return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
  } else {
    return `${date.getUTCDate()}.${date.getUTCMonth() + 1}.${date.getUTCFullYear()}`;
  }
};

export const dateTimeToTimeString = (dateTime: DateTime) => dateTime.toLocaleString(DateTime.TIME_SIMPLE);
