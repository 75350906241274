import {VatIdFragment, WixCommonVatType} from '../../gql/graphql';
import {CheckoutModel} from '../models/Checkout.model';
import {COUNTRIES_WITH_VAT} from '../../components/Checkout/constants';

interface getCommonVatItParams {
  id?: string;
  type?: string;
}

export const getCommonVatId = (params?: getCommonVatItParams): VatIdFragment | undefined => {
  if (!params) {
    return undefined;
  }
  const {id, type} = params;
  if (id) {
    return {
      id,
      type: getCommonVatType(type),
    };
  }
  return undefined;
};

const getCommonVatType = (type?: string): WixCommonVatType => {
  switch (type) {
    /* istanbul ignore next */ case 'CPF':
      return /* istanbul ignore next */ WixCommonVatType.CPF;
    case 'CNPJ':
      return /* istanbul ignore next */ WixCommonVatType.CNPJ;
    /* istanbul ignore next */ default:
      return /* istanbul ignore next */ WixCommonVatType.UNSPECIFIED;
  }
};

export const isCountryWithVAT = (checkout: CheckoutModel) => {
  const billingCountry = checkout.billingInfo?.address?.country ?? '';
  const shippingCountry = checkout.shippingDestination?.address?.country ?? '';
  return COUNTRIES_WITH_VAT.includes(billingCountry) || COUNTRIES_WITH_VAT.includes(shippingCountry);
};
