import {FormValues} from '@wix/form-viewer';
import {FullNameValue} from '@wix/form-fields';
import {CheckoutSettingsModel} from '../../../../domain/models/checkoutSettings/CheckoutSettings.model';
import {ContactModel} from '../../../../domain/models/Contact.model';
import {DEFAULT_COUNTRY} from '../../constants';
import {ILocaleKeys} from '../../../../locale-keys/LocaleKeys';
import {FullAddressContactDetailsFragment} from '../../../../gql/graphql';

/* istanbul ignore next */
export const getContactFormOverrides = ({
  localeKeys,
  checkoutSettings,
  overridePhone,
  checkoutComposerEnabled,
}: {
  localeKeys?: ILocaleKeys;
  checkoutSettings: CheckoutSettingsModel;
  overridePhone?: boolean;
  checkoutComposerEnabled: boolean;
}) => {
  const isPhoneShown = overridePhone ?? (checkoutComposerEnabled ? undefined : checkoutSettings.phone.show);
  const isPhoneRequired = overridePhone ?? (checkoutComposerEnabled ? undefined : checkoutSettings.phone.mandatory);

  return {
    full_name: {
      first_name: {
        ...(localeKeys ? {label: localeKeys.checkout.address_information.first_name_label()} : {}),
      },
      last_name: {
        ...(localeKeys ? {label: localeKeys.checkout.address_information.last_name_label()} : {}),
      },
    },
    phone: {
      ...(localeKeys ? {label: localeKeys.checkout.address_information.phone_number_label()} : {}),
      ...(typeof isPhoneShown === 'undefined' ? {} : {hidden: !isPhoneShown}),
      ...(typeof isPhoneRequired === 'undefined' ? {} : {required: isPhoneRequired}),
    },
    company_name: {
      ...(localeKeys ? {label: localeKeys.checkout.address_information.company_label()} : {}),
      ...(checkoutComposerEnabled ? {} : {hidden: !checkoutSettings.companyName.show}),
      ...(checkoutComposerEnabled ? {} : {required: checkoutSettings.companyName.mandatory}),
    },
  };
};

export const getContactFormInitialState = ({
  checkoutSettings,
  contact,
  country = DEFAULT_COUNTRY,
  overridePhone,
  checkoutComposerEnabled,
}: {
  checkoutSettings: CheckoutSettingsModel;
  contact?: ContactModel;
  country?: string;
  overridePhone?: boolean;
  checkoutComposerEnabled: boolean;
}): FormValues => {
  return {
    full_name: {
      first_name: contact?.firstName ?? '',
      last_name: contact?.lastName ?? '',
      country,
    },
    ...(checkoutComposerEnabled || checkoutSettings.phone.show || overridePhone ? {phone: contact?.phone ?? ''} : {}),
    ...(checkoutComposerEnabled || checkoutSettings.companyName.show ? {company_name: contact?.company ?? ''} : {}),
  };
};

export const getContactFormCurrentStateWithUpdatedCountry = ({
  contactFormValues,
  country,
}: {
  contactFormValues: FormValues;
  country?: string;
}): FormValues => {
  const fullName = contactFormValues.full_name as FullNameValue;
  return {
    ...contactFormValues,
    full_name: {
      ...fullName,
      country,
    },
  };
};

export const getContactDetailsFromContactFormValues = (
  contactFormValues: FormValues,
  checkoutSettings: CheckoutSettingsModel,
  checkoutComposerEnabled: boolean,
  overridePhone?: boolean
): FullAddressContactDetailsFragment => {
  const fullName = contactFormValues.full_name as FullNameValue;
  return {
    firstName: fullName.first_name as string,
    lastName: fullName.last_name as string,
    ...((checkoutSettings.phone.show || overridePhone || checkoutComposerEnabled) && contactFormValues.phone
      ? {phone: contactFormValues.phone as string}
      : {}),
    ...((checkoutSettings.companyName.show || checkoutComposerEnabled) && contactFormValues.company_name
      ? {company: contactFormValues.company_name as string}
      : {}),
  };
};
